import * as React from "react";
import { observer } from "mobx-react";
import { DialogProps, TablePriceTrendProps } from "../../typings";
import { UitkLink } from "@egds/react-core/link";
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from "@egds/react-core/sheet";
import { UitkToolbar, UitkToolbarProps } from "@egds/react-core/toolbar";
import { UitkSpacing } from "@egds/react-core/spacing";
import TablePriceTrend from "../TablePriceTrend/TablePriceTrend";
import Disclaimer from "../Disclaimer";
import { useDialog } from "@shared-ui/dialog-context";

type DialogExtendedProps = DialogProps & TablePriceTrendProps;

const TablePriceTrendDialog = observer((props: DialogExtendedProps) => {
  const { title, iconLabel, headerRow, priceTrend, linkText, linkA11yLabel, disclaimer, titleDetailsA11y } = props;

  if (!headerRow || !priceTrend || !linkText) {
    return null;
  }

  const [isDialogOpen, dialogActions, DialogComponent] = useDialog("TablePriceTrend");
  const triggerRef = React.createRef<HTMLElement>();

  const toolbarProps: UitkToolbarProps = {
    toolbarTitle: title,
    navigationContent: {
      navIconLabel: iconLabel,
      onClick: dialogActions.closeDialog as any,
    },
  };

  const tableProps: TablePriceTrendProps = {
    headerRow,
    priceTrend,
    titleDetailsA11y,
  };

  return (
    <>
      <UitkSpacing padding={{ blockstart: "three", blockend: "three" }} border={["blockstart"]}>
        <UitkLink inline onClick={dialogActions.openDialog as any}>
          <button>
            <span aria-hidden>{linkText}</span>
            <span className="is-visually-hidden">{linkA11yLabel}</span>
          </button>
        </UitkLink>
      </UitkSpacing>
      <UitkSheetTransition isVisible={isDialogOpen}>
        <DialogComponent>
          <UitkSheet type="full" triggerRef={triggerRef} isVisible={isDialogOpen}>
            <UitkToolbar {...toolbarProps} />
            <UitkSheetContent>
              <TablePriceTrend {...tableProps} />
              <Disclaimer disclaimer={disclaimer} />
            </UitkSheetContent>
          </UitkSheet>
        </DialogComponent>
      </UitkSheetTransition>
    </>
  );
});

export default TablePriceTrendDialog;
