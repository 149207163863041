import * as React from "react";
import { observer } from "mobx-react";
import { DisclaimerProps } from "../typings";
import { UitkText } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

const Disclaimer = observer((props: DisclaimerProps) => {
  const { disclaimer } = props;

  return (
    <UitkSpacing padding={{ block: "six" }}>
      <UitkText size={200} align="center" weight="regular">
        {disclaimer}
      </UitkText>
    </UitkSpacing>
  );
});

export default Disclaimer;
