import * as React from "react";
import { observer } from "mobx-react";
import { HighlightsProps } from "../../typings";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkTable, UitkTableHead, UitkTableBody, UitkTableRow, UitkTableCell } from "@egds/react-core/tables";

const Highlights = observer((props: HighlightsProps) => {
  const { highlights, titleSummaryA11y } = props;

  /* istanbul ignore next */
  if (!highlights) {
    return null;
  }

  const spacingProps = (index: number): UitkSpacingProps => {
    const lastIndex = highlights.length - 1;

    if (index === 0) {
      return {
        padding: {
          medium: { inlineend: "twelve" },
          small: { inlineend: "three" },
        },
      };
    }

    if (index === lastIndex) {
      return {
        padding: {
          medium: { inlinestart: "twelve" },
          small: { inlinestart: "three" },
        },
      };
    }

    return {
      padding: {
        medium: { inlinestart: "twelve", inlineend: "twelve" },
        small: { inlinestart: "three", inlineend: "three" },
      },
    };
  };

  return (
    <UitkSpacing padding={{ block: "four" }}>
      <div id="highlights">
        <UitkTable summary={titleSummaryA11y}>
          <UitkTableHead>
            <UitkTableRow>
              {highlights.map((it, index) => (
                <UitkSpacing key={`highlights_0_title_${index}`} padding="unset">
                  <UitkTableCell>
                    <UitkSpacing {...spacingProps(index)}>
                      <UitkText weight="regular" theme="emphasis" whiteSpace="nowrap">
                        {it.title}
                      </UitkText>
                    </UitkSpacing>
                  </UitkTableCell>
                </UitkSpacing>
              ))}
            </UitkTableRow>
          </UitkTableHead>
          <UitkTableBody>
            <UitkTableRow>
              {highlights.map((it, index) => (
                <UitkSpacing key={`highlights_0_content_${index}`} padding="unset">
                  <UitkTableCell>
                    <UitkSpacing {...spacingProps(index)}>
                      <UitkText weight="bold" size={500}>
                        {it.content}
                      </UitkText>
                    </UitkSpacing>
                  </UitkTableCell>
                </UitkSpacing>
              ))}
            </UitkTableRow>
          </UitkTableBody>
        </UitkTable>
      </div>
    </UitkSpacing>
  );
});

export default Highlights;
