import * as React from "react";
import { observer } from "mobx-react";
import { BarChartPriceTrendProps } from "../../typings";
import { IBarChartOptions, IChartistData } from "chartist";
import { Tooltip, TooltipProps } from "components/shared/Tooltip/Tooltip";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { UitkSpacing } from "@egds/react-core/spacing";

export interface GraphChart {
  container: HTMLDivElement;
  on: (event: string, handler: (data: ChartDrawData) => void) => void;
}

export interface BarChartPriceTrendState {
  showTooltip: boolean;
  tooltipProps: TooltipProps;
}

export interface ChartDrawData {
  index: number;
  type: string;
  seriesIndex: number;
  element: ChartNode;
}

export interface ChartNode {
  _node: SVGLineElement;
  addClass: (names: string) => void;
}

@observer
export class BarChartPriceTrend extends React.Component<BarChartPriceTrendProps, BarChartPriceTrendState> {
  private graph: GraphChart;

  constructor(props: BarChartPriceTrendProps) {
    super(props);

    this.state = {
      showTooltip: false,
      tooltipProps: {
        width: 0,
        left: 0,
        top: 0,
        content: "",
      },
    };

    this.createGraph = this.createGraph.bind(this);
    this.chartDidDraw = this.chartDidDraw.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  public componentDidMount() {
    import(/* webpackChunkName: "chartist" */ "chartist").then((Chartist: any) => {
      this.graph = this.createGraph(Chartist);
      this.graph.on("draw", this.chartDidDraw);
    });
  }

  public componentWillUnmount() {
    window.removeEventListener("mouseenter", this.showTooltip(0));
    window.removeEventListener("mouseout", this.hideTooltip());
  }

  public createGraph(Chartist: any) {
    const selector = `#${this.props.id}`;

    const data: IChartistData = {
      labels: this.chartLabels,
      series: this.chartValue,
    };

    const options: IBarChartOptions = {
      high: this.maxChartValue,
      low: this.minChartValue,
      axisY: {
        labelInterpolationFnc: (value: number) => `${this.props.simpleCurrencyFormat.replace("#", value.toString())}`,
        labelOffset: {
          y: this.props.isMobile ? 8 : 10,
        },
        offset: this.getOffset(),
      },
      distributeSeries: true,
      chartPadding: { right: 0, left: 0 },
    };

    return new Chartist.Bar(selector, data, options);
  }

  public getOffset(): number {
    const yOffsetPerChar = 9.1;
    const maxLabel = this.chartValueUnit() + this.maxChartValue;

    return maxLabel.length * yOffsetPerChar;
  }

  private chartValueUnit(): string {
    return `${this.props.simpleCurrencyFormat.replace("#", "")}`;
  }

  public get maxChartValue(): number {
    const maxValue = Math.max(...this.chartValue);

    // Nearest $25 increment above the highest data point +33.3%
    return Math.round((maxValue + maxValue * 0.333) / 25) * 25;
  }

  public get minChartValue(): number {
    const minValue = Math.min(...this.chartValue);

    // Nearest $25 increment below the lowest data point -33.3%
    return Math.round((minValue - minValue * 0.333) / 25) * 25;
  }

  public get chartLabels(): string[] {
    return this.props.priceTrend.map((item) => item.shortLabel);
  }

  public get chartValue(): number[] {
    return this.props.priceTrend.map((item) => item.price);
  }

  public chartDidDraw({ element, seriesIndex }: ChartDrawData) {
    if (seriesIndex !== undefined) {
      element._node.addEventListener("mouseenter", this.showTooltip(seriesIndex));
      element._node.addEventListener("mouseout", this.hideTooltip());
    }
  }

  public showTooltip(seriesIndex: number) {
    return (event: MouseEvent & { target: SVGLineElement }) => {
      const barElement = event.target as SVGLineElement;
      const container = this.graph.container;
      const lineBox = barElement.getBBox();
      event.stopPropagation();

      this.setState({
        showTooltip: true,
        tooltipProps: {
          width: container.clientWidth,
          left: lineBox.x,
          top: lineBox.y,
          content: this.props.priceTrend[seriesIndex].displayPrice,
        },
      });
    };
  }

  public hideTooltip() {
    return (event: MouseEvent & { target: SVGLineElement }) => {
      this.setState({
        showTooltip: false,
      });
    };
  }

  public render() {
    const { id } = this.props;
    const { showTooltip, tooltipProps } = this.state;

    return (
      <UitkSpacing padding={{ small: { blockstart: "six" }, large: { block: "six" } }}>
        <div className="BarChartPriceTrend" aria-hidden="true">
          <div className="chartContainer">
            {showTooltip && <Tooltip {...tooltipProps} />}
            <Viewport>
              <ViewSmall>
                <div id={id} className="chart-mobile" />
              </ViewSmall>
              <ViewMedium>
                <div id={id} className="chart" />
              </ViewMedium>
            </Viewport>
          </div>
        </div>
      </UitkSpacing>
    );
  }
}

export default BarChartPriceTrend;
