import * as React from "react";
import { observer } from "mobx-react";
import { TablePriceTrendProps } from "../../typings";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkTable, UitkTableHead, UitkTableBody, UitkTableRow, UitkTableCell } from "@egds/react-core/tables";

const TablePriceTrend = observer((props: TablePriceTrendProps) => {
  const { headerRow, priceTrend, titleDetailsA11y } = props;

  if (!headerRow || !priceTrend) {
    return null;
  }

  return (
    <UitkSpacing padding={{ block: "three", inlinestart: "three" }}>
      <div>
        <UitkTable summary={titleDetailsA11y}>
          <UitkTableHead>
            <UitkTableRow>
              {headerRow?.map((it, index) => (
                <UitkSpacing padding="two" key={`table-price-trend-0-header-${index}`}>
                  <UitkTableCell>
                    <UitkText weight="bold" size={400} align={index === headerRow.length - 1 ? "right" : undefined}>
                      {it}
                    </UitkText>
                  </UitkTableCell>
                </UitkSpacing>
              ))}
            </UitkTableRow>
          </UitkTableHead>
          <UitkTableBody>
            {priceTrend?.map((row, index) => (
              <UitkTableRow key={index}>
                <UitkSpacing padding="two">
                  <UitkTableCell>
                    <UitkText weight="medium" size={300}>
                      {row.label}
                    </UitkText>
                  </UitkTableCell>
                </UitkSpacing>
                <UitkSpacing padding="two">
                  <UitkTableCell>
                    <UitkText weight="medium" size={300} align="right">
                      {row.displayPrice}
                    </UitkText>
                  </UitkTableCell>
                </UitkSpacing>
              </UitkTableRow>
            ))}
          </UitkTableBody>
        </UitkTable>
      </div>
    </UitkSpacing>
  );
});

export default TablePriceTrend;
