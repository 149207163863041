import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { UitkHeading } from "@egds/react-core/text";
import {
  BarChartPriceTrendProps,
  PriceTrendGraphProps,
  TablePriceTrendProps,
  DialogProps,
  HighlightsProps,
} from "./typings";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import BarChartPriceTrend from "./components/BarChartPriceTrend/BarChartPriceTrend";
import Disclaimer from "./components/Disclaimer";
import Highlights from "./components/Highlights/Highlights";
import TablePriceTrend from "./components/TablePriceTrend/TablePriceTrend";
import TablePriceTrendDialog from "./components/TablePriceTrend/TablePriceTrendDialog";
import { PriceTrendGraphFlexModuleResult } from "typings/microserviceModels/price-trend-graph-flex-module";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "src/components/utility/analytics/FlexAnalyticsUtils";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";

export const PriceTrendGraph = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: PriceTrendGraphProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;
    const track = useClickTracker();

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const {
      metadata: { id },
    } = templateComponent;
    const { hideTitle } = templateComponent.config;
    const model = flexModuleModelStore.getModel(id) as PriceTrendGraphFlexModuleResult | null;

    if (!model?.priceTrend) {
      return null;
    }

    React.useEffect(() => {
      const flexTracking: FlexTrackingInfo = {
        moduleName: "PriceTrendGraph",
        rfrr: "HasData",
        action: Action.IMPRESSION,
      };

      sendImmediateClickstreamTrackEvent(flexTracking, track);
    }, []);

    const {
      priceTrend,
      disclaimer,
      tableHeaderRow,
      tableTitleSummaryA11y,
      tableTitleDetailsA11y,
      highlights,
      toolbarIconLabel,
      dialogLinkText,
      dialogLinkA11yLabel,
      simpleCurrencyFormat,
    } = model;

    const barChartProps: BarChartPriceTrendProps = {
      id: `graph-${id}`,
      priceTrend,
      disclaimer,
      simpleCurrencyFormat,
    };

    const tableProps: TablePriceTrendProps = {
      headerRow: tableHeaderRow,
      priceTrend,
      titleDetailsA11y: tableTitleDetailsA11y,
    };

    const highlightsProps: HighlightsProps = {
      highlights,
      titleSummaryA11y: tableTitleSummaryA11y,
    };

    const dialogProps: DialogProps = {
      disclaimer,
      title: model.title || "",
      iconLabel: toolbarIconLabel,
      linkText: dialogLinkText,
      linkA11yLabel: dialogLinkA11yLabel,
    };

    const PriceTrendGraphDesktopView = () => (
      <>
        <UitkLayoutGrid columns={2} rows={1} space="two">
          <>
            <UitkLayoutGridItem rowSpan="all" colSpan={2}>
              <div>
                <Highlights {...highlightsProps} />
                <BarChartPriceTrend {...barChartProps} />
              </div>
            </UitkLayoutGridItem>
            <UitkLayoutGridItem rowSpan="all" colSpan={1}>
              <div>
                <TablePriceTrend {...tableProps} />
              </div>
            </UitkLayoutGridItem>
          </>
        </UitkLayoutGrid>
        <Disclaimer disclaimer={disclaimer} />
      </>
    );

    const PriceTrendGraphDialogView = () => (
      <>
        <Viewport>
          <ViewSmall>
            <Highlights {...highlightsProps} isMobile />
          </ViewSmall>
          <ViewMedium>
            <Highlights {...highlightsProps} isMobile={false} />
          </ViewMedium>
        </Viewport>
        <BarChartPriceTrend {...barChartProps} isMobile />
        <Disclaimer disclaimer={disclaimer} />
        <TablePriceTrendDialog {...dialogProps} {...tableProps} />
      </>
    );

    return (
      <LazyLoad context={context}>
        <UitkSpacing padding="three">
          <UitkCard>
            <UitkCardContentSection>
              <div id={id}>
                <div className="price-graph-title">
                  {!hideTitle && (
                    <div className="price-graph-title">
                      <UitkHeading tag="h2" size={4}>
                        {model.title}
                      </UitkHeading>
                    </div>
                  )}
                </div>
                <Viewport>
                  <ViewSmall>
                    <PriceTrendGraphDialogView />
                  </ViewSmall>
                  <ViewLarge>
                    <PriceTrendGraphDesktopView />
                  </ViewLarge>
                </Viewport>
              </div>
            </UitkCardContentSection>
          </UitkCard>
        </UitkSpacing>
      </LazyLoad>
    );
  })
);

export default PriceTrendGraph;
